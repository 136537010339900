import React, { useState } from "react";
import Stars from "../../components/global/Logout/Stars";



function Faq() {

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text">
                {isReadMore ? text.slice(0, 100) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };
    const moverReview = [
        {
            name: "Wakia Hayward",
            date: "2 months ago",
            // tittle: "Movers on the way is the best!",
            description: "Best cleaning service ever.  They made my home look and smell brand new.  Highly recommend.",
            rate: 5,
            red: "Google",
            enlace: 'https://maps.app.goo.gl/UWp1dG5HsG1C4kf18',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/material%20Dash%2F2000px-Google_G_Logo.svg_.png?alt=media&token=a0b834d8-25ae-45a1-bdd2-ecd81fbddf2b',
        },

        {
            name: "Mathew Cademartori",
            date: "1 Year ago",
            // tittle: "Movers on the way is the best!",
            description: "I called and they came right over and did a fantastic job cleaning my house and windows I highly recommend this company they are reliable and do a great job! Thank you so much",
            rate: 5,
            red: "Google",
            enlace: 'https://maps.app.goo.gl/UWp1dG5HsG1C4kf18',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/material%20Dash%2F2000px-Google_G_Logo.svg_.png?alt=media&token=a0b834d8-25ae-45a1-bdd2-ecd81fbddf2b',
        },
        {
            name: "Lizbeth Ramos",
            date: "1 Year ago",
            // tittle: "Movers on the way is the best!",
            description: "",
            rate: 5,
            red: "Google",
            enlace: 'https://maps.app.goo.gl/UWp1dG5HsG1C4kf18',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/material%20Dash%2F2000px-Google_G_Logo.svg_.png?alt=media&token=a0b834d8-25ae-45a1-bdd2-ecd81fbddf2b',
        },




    ];


    return (
        <section className="flex flex-col items-center my-5">
            <h2>Our Reviews</h2>
            <div className="w-4/5 h-auto my-10 mx-auto gap-5 grid md:grid-cols-3 grid-cols-1">

                {
                    moverReview.map((item, index) => {
                        return (
                            <article key={index} className="h-auto p-2 flex flex-col rounded-3xl bg-gray-100 w-auto">
                                <div className="flex flex-col">
                                    <div className="p-2 flex space-x-2">
                                        <div className="flex justify-center">
                                            <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/user.jfif?alt=media&token=2e7c59b5-a71c-4739-8260-bb88b37b08fa"
                                                alt="perfil-foto"
                                                className="w-[50px] h-auto rounded-full object-cover" />
                                        </div>
                                        <div>
                                            <span className="text-[20px] font-semibold"> {item.name} </span>
                                            <Stars score={item.rate} />
                                        </div>
                                        <div className="flex items-end text-[13px] text-gray-400">
                                            <span>{item.date}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-5">
                                    {
                                        item.description.length > 1 ? <ReadMore >{item.description}</ReadMore> : null
                                    }

                                </div>
                                <div className="w-full flex items-center space-x-3 ml-3">
                                    <div className="w-[25px] h-[25px] flex items-center">
                                        <img src={item.ima} alt="agni" className="w-full mx-auto" />
                                    </div>
                                    <div className="flex flex-col items-center justify-start">
                                        <p>Posted On</p>
                                        <a className="text-blue-500 w-full" href={item.enlace}>{item.red}</a>
                                    </div>
                                </div>
                            </article>
                        );
                    })
                }
            </div>
        </section>

    );
}

export default Faq;